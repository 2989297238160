.wrapper {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  }
  
  .title {
    margin-bottom: var(--mantine-spacing-md);
    padding-left: var(--mantine-spacing-md);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
  }
  
  .item {
    font-size: var(--mantine-font-size-sm);
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  }